export enum BlockTypes {
  WHEN_RUN = 'when_run',
  WHEN_RUN_SIMPLE2 = 'when_run_simple2',
  TRIGGERED_AT = 'triggered_at',
  TRIGGERED_AT_SIMPLE = 'triggered_at_simple',
  TRIGGERED_AT_SIMPLE2 = 'triggered_at_simple2',
  PLAY_SOUND = 'play_sound',
  FOR_LOOP = 'for_loop',
  PLAY_SOUND_AT_CURRENT_LOCATION = 'play_sound_at_current_location',
  SET_CURRENT_LOCATION_NEXT_MEASURE = 'set_current_location_next_measure',
  PLAY_SOUND_AT_CURRENT_LOCATION_SIMPLE2 = 'play_sound_at_current_location_simple2',
  PLAY_PATTERN_AT_CURRENT_LOCATION_SIMPLE2 = 'play_pattern_at_current_location_simple2',
  PLAY_PATTERN_AI_AT_CURRENT_LOCATION_SIMPLE2 = 'play_pattern_ai_at_current_location_simple2',
  PLAY_CHORD_AT_CURRENT_LOCATION_SIMPLE2 = 'play_chord_at_current_location_simple2',
  PLAY_TUNE_AT_CURRENT_LOCATION_SIMPLE2 = 'play_tune_at_current_location_simple2',
  PLAY_REST_AT_CURRENT_LOCATION_SIMPLE2 = 'play_rest_at_current_location_simple2',
  SET_EFFECT_AT_CURRENT_LOCATION_SIMPLE2 = 'set_effect_at_current_location_simple2',
  SET_VOLUME_EFFECT_AT_CURRENT_LOCATION_SIMPLE2 = 'set_volume_effect_at_current_location_simple2',
  SET_FILTER_EFFECT_AT_CURRENT_LOCATION_SIMPLE2 = 'set_filter_effect_at_current_location_simple2',
  SET_DELAY_EFFECT_AT_CURRENT_LOCATION_SIMPLE2 = 'set_delay_effect_at_current_location_simple2',
  PLAY_SOUNDS_TOGETHER = 'play_sounds_together',
  PLAY_SOUNDS_SEQUENTIAL = 'play_sounds_sequential',
  PLAY_SOUNDS_RANDOM = 'play_sounds_random',
  REPEAT_SIMPLE2 = 'repeat_simple2',
  NEW_TRACK_AT_START = 'new_track_at_start',
  NEW_TRACK_AT_MEASURE = 'new_track_at_measure',
  NEW_TRACK_ON_TRIGGER = 'new_track_on_trigger',
  PLAY_SOUND_IN_TRACK = 'play_sound_in_track',
  REST_IN_TRACK = 'rest_in_track',
  VALUE_SAMPLE = 'value_sample',
}
